<template>
    <div class="tool-tips">
        <div class="tool-tip tip-general">Align the car as close as you can to the guide</div>
        <div class="tool-tip tip-body">Change Car Body Type</div>
        <div class="tool-tip tip-close">Exit Photo Mode</div>
        <div class="tool-tip tip-shoot">Take Photo</div>
        <div class="tool-tip tip-angle">Change Photo Angle</div>
    </div>
</template>
<script>
export default {
  name: 'Tooltips',
}
</script>
<style lang="scss">
.tool-tip {
    position: absolute;
    background: #2BB673;
    border: 2px solid #FFFFFF;
    border-radius: 20.5px;
    text-align: center;
    line-height: 30px;
    z-index: 1800;
    text-orientation: mixed;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    font-family: "Avenir Next LT W05 Demi";
    color: #ffffff;
}
// General tip
.tip-general {
    height: 365px;
    width: 35px;
    left: 5px;
    top: 5px;
}
// Body type tip
.tip-body {
    height: 250px;
    width: 35px;
    left: 70px;
    bottom: 100px;
}
.tip-body:before,
.tip-body:after,
.tip-shoot:before,
.tip-shoot:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #2BB673 transparent;
    border-width: 22px 12px 0;
    bottom: -11px;
    left: 50%;
    margin-left: -12px;
}
.tip-body:before, .tip-shoot:before {
    border-color: #ffffff transparent;
    border-width: 22px 12px 0;
    bottom: -18px;
    left: 50%;
    margin-left: -12px;
}
// Shoot tip
.tip-shoot {
    height: 170px;
    width: 35px;
    left: 50%;
    bottom: 165px;
    transform: translateX(calc(-50% + 10px));
}
// Close Tip
.tip-close {
    height: 175px;
    width: 35px;
    right: 35px;
    bottom: 90px;
}
.tip-close:before, 
.tip-close:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #2BB673;
    border-top: 10px solid #2BB673;
    border-bottom: 10px solid transparent;
    right: 8px;
    bottom: -12px;
}
.tip-close:before {
    border-left: 10px solid transparent;
    border-right: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid transparent;
    right: 6px;
    bottom: -17px;
}
// Angle Tip
.tip-angle {
    height: 250px;
    width: 35px;
    right: 70px;
    top: 50%;
    transform: translateY(calc(-50% - 60px));
}
.tip-angle:before,
.tip-angle:after {
    content: " ";
    display: block;
    position: absolute;
    bottom: 15px;
    right: -12px;
    z-index: 2;
    width: 0;
    height: 0;
    overflow: hidden;
    border: solid 14px transparent;
    border-right: 0;
    border-left-color: #2BB673;
}
.tip-angle:before {
    bottom: 15px;
    right: -15px;
    z-index: 1;
    border-left-color: #ffffff;
}

@media screen and (orientation: landscape){
.tool-tip {
    text-orientation: unset;
    writing-mode: unset;
    font-size: 15px;
    }
    // General tip
    .tip-general {
    width: 365px;
    height: 35px;
    bottom: 5px;
    top: unset;
    }
    // Body type tip
    .tip-body {
    right: 90px;
    width: 250px;
    height: 35px;
    left: unset;
    bottom: 75px;
    }
    // Shoot tip
    .tip-shoot {
    width: 170px;
    height: 35px;
    top: 50%;
    right: 150px !important;
    left: unset;
    bottom: unset;
    transform: translateY(-50%);
    }
    .tip-body:before,
    .tip-body:after,
    .tip-shoot:before,
    .tip-shoot:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent #2BB673;
    border-width: 12px 0 10px 25px;
    top: unset;
    right: -12px;
    left: unset;
    bottom: 4px;
    }
    .tip-body:before, .tip-shoot:before {
    border-color: transparent #ffffff;
    border-width: 12px 0 9px 20px;
    bottom: 4px;
    right: -17px;
    }
    // Close tip
    .tip-close {
    width: 175px;
    height: 35px;
    bottom: unset;
    top: 45px;
    right: 80px;
    }
    .tip-close:before, 
    .tip-close:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 7px solid #2BB673;
    border-right: 7px solid transparent;
    border-top: 7px solid #2BB673;
    border-bottom: 7px solid transparent;
    right: -8px;
    top: 9px;
    }
    .tip-close:before {
    border-left: 7px solid #fff;
    border-top: 7px solid #fff;
    right: -13px;
    top: 6px;
    }
    // Angle tip
    .tip-angle {
    width: 250px;
    height: 35px;
    top: 60px;
    right: 50%;
    transform: translateX(calc(50% - 60px));
    }
    .tip-angle:before,
    .tip-angle:after {
    content: " ";
    display: block;
    position: absolute;
    top: -12px;
    right: 20px;
    z-index: 2;
    width: 0;
    height: 0;
    overflow: hidden;
    border: solid 14px transparent;
    border-top: 0;
    border-bottom-color: #2BB673;
    }
    .tip-angle:before {
    top: -15px;
    z-index: 1;
    border-bottom-color: #ffffff;
    }
}
</style>